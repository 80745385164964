// css
import '../styles/main.scss'

// vendor
import '@babel/polyfill'
import ready from 'document-ready'
import Webfontloader from 'webfontloader'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes'

// utils
import './utils/foreach-polyfill.js'
import detectTouch from './utils/detect-touch'
import ModuleRouter from './utils/module-router'

// partials
import loader from './partials/loader'

// modular
import Intro from './modular/intro'
import imagesLoaded from 'imagesloaded'

// for ie11 support (optional)
const ES6Promise = require('es6-promise')
ES6Promise.polyfill()

ready(function () {
  detectTouch.init()

  Webfontloader.load({
    custom: { families: ['GT-Cinetype-Regular', 'GT-Cinetype-Light'] },
    active: () => { init() },
    inactive: () => { init() }
  })
})

function init () {
  // Router Modules
  const modularClasses = {
    'Intro': Intro
  }

  const moduleRouter = new ModuleRouter(modularClasses)

  // load and fire init event
  const criticalLazyLoadImgs = document.querySelectorAll('.js-criticalLazyLoad')
  for (let i = 0; i < criticalLazyLoadImgs.length; i++) {
    criticalLazyLoadImgs[i].src = criticalLazyLoadImgs[i].dataset.src
    delete criticalLazyLoadImgs[i].dataset.src
  }

  imagesLoaded(document.documentElement, { background: true }, () => {
    moduleRouter.fireEvent('init')
    loader.hide()
  })
}

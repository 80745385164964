const detectTouch = {

  init () {
    this.hasTouch()
    this.hasHover()
  },

  checkForTouch () {
    return 'ontouchstart' in document.documentElement ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
  },

  hasTouch () {
    const touchEnabled = this.checkForTouch()
    document.documentElement.setAttribute('data-has-touch', touchEnabled)

    return touchEnabled
  },

  hasHover () {
    const touchEnabled = this.checkForTouch()
    document.documentElement.setAttribute('data-has-hover', !touchEnabled)

    return !touchEnabled
  }
}

export default detectTouch

import anime from 'animejs'

const loader = {
  el: document.querySelector('.js-loader'),
  bg: document.querySelector('.js-loaderBg'),

  show (includeBackground = true) {
    this.el.style.display = 'block'
    console.log('loader show')

    if (includeBackground) {
      this.bg.style.display = 'block'
    } else {
      this.bg.style.display = 'none'
    }

    anime({
      targets: this.el,
      opacity: 1,
      duration: 500,
      delay: 200,
      easing: 'easeInOutQuart'
    })
  },

  hide (delay = 200) {
    var _this = this

    anime({
      targets: this.el,
      opacity: [1, 0],
      duration: 500,
      delay: delay,
      easing: 'easeInOutQuart',
      complete: function () {
        _this.el.style.display = 'none'
      }
    })

    document.documentElement.classList.remove('is-loading')
  }
}

export default loader

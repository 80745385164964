import Marquee3k from 'marquee3000'

class Announcements {
  constructor (el) {
    this.el = el
    this.className = 'js-announcements'
    this.el.dataset.speed = '0.75'

    Marquee3k.init({ selector: this.className })
  }
}

export default Announcements

import anime from 'animejs'
import Dom from '../utils/dom'
import Announcements from '../partials/announcements'

class Intro {
  constructor (el) {
    this.dom = new Dom(el, [
      'announcements', 
      'sizer'
    ])
    this.announcements = new Announcements(this.dom.announcements[0])

    // fix safari height issue
    if (window.matchMedia('(max-width: 768px)').matches) {
      this.dom.root.style.height = this.dom.sizer[0].offsetHeight + 'px'
    }
  }

  init () {
    anime({
      targets: this.dom.root,
      opacity: [0, 1],
      duration: 500,
      delay: 200,
      easing: 'easeInOutQuart'
    })
  }
}

export default Intro
